import {
  FC,
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export type DrawerMenuContextType = [
  boolean,
  Dispatch<SetStateAction<boolean>>
];

export const DrawerMenuContext = createContext<DrawerMenuContextType>([
  true,
  () => {},
]);

export const DrawerMenuContextProvider: FC<{}> = ({ children }) => {
  const [isDrawerMenuOpen, setIsDrawerMenuOpen] = useState(false);

  return (
    <DrawerMenuContext.Provider value={[isDrawerMenuOpen, setIsDrawerMenuOpen]}>
      {children}
    </DrawerMenuContext.Provider>
  );
};

const useDrawerMenu = () => useContext(DrawerMenuContext);

export default useDrawerMenu;
