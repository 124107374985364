import { FC, MouseEvent } from "react";
import { Theme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TranslateIcon from "@mui/icons-material/Translate";
import NotificationsIcon from "@mui/icons-material/Notifications";

export interface HeaderProps {
  /**
   * AppBar Title
   */
  title: string;
  /**
   * Url to show logo
   */
  logoUrl?: string;
  /**
   * Username
   */
  userName?: string;
  /**
   * Notification count badge
   */
  notificationsCount?: number;
  /**
   * Search handler
   */
  onSearchClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Help handler
   */
  onHelpClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Notification handler
   */
  onNotificationClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Profile handler
   */
  onProfileClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Languages handler
   */
  onLanguagesClick?: (event: MouseEvent<HTMLElement>) => void;
}

const Header: FC<HeaderProps> = ({
  children,
  title,
  logoUrl,
  userName,
  onSearchClick,
  onHelpClick,
  onNotificationClick,
  onProfileClick,
  onLanguagesClick,
  notificationsCount = 0,
}) => (
  <AppBar
    position="fixed"
    sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
  >
    <Toolbar>
      {logoUrl && <img src={logoUrl} alt={title} width={132} />}
      {!logoUrl && (
        <Typography variant="h6" noWrap component="div">
          {title}
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {children}
      <Box>
        {onSearchClick && (
          <IconButton
            onClick={onSearchClick}
            sx={{ display: { xs: "none", sm: "inline-flex" } }}
            size="large"
            aria-label="search"
            color="inherit"
          >
            <SearchIcon />
          </IconButton>
        )}
        {onHelpClick && (
          <IconButton
            onClick={onHelpClick}
            sx={{ display: { xs: "none", sm: "inline-flex" } }}
            size="large"
            aria-label="help"
            color="inherit"
          >
            <HelpOutlineIcon />
          </IconButton>
        )}
        {onNotificationClick && (
          <IconButton
            onClick={onNotificationClick}
            size="large"
            aria-label={`show ${notificationsCount} new notifications`}
            color="inherit"
          >
            <Badge badgeContent={notificationsCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        )}
        {userName && (
          <Button
            onClick={onProfileClick}
            sx={{ display: { xs: "none", sm: "inline-flex" } }}
            size="large"
            aria-label="account of current user"
            color="inherit"
            startIcon={<AccountCircleIcon />}
          >
            {userName}
          </Button>
        )}
        {onLanguagesClick && (
          <IconButton
            onClick={onLanguagesClick}
            sx={{ display: { xs: "none", sm: "inline-flex" } }}
            size="large"
            edge="end"
            aria-label="languages"
            color="inherit"
          >
            <TranslateIcon />
          </IconButton>
        )}
      </Box>
    </Toolbar>
  </AppBar>
);

export default Header;
