import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import LogoutIcon from "@mui/icons-material/Logout";
import MainLayout from "@chambercustoms/common-components/src/components/MainLayout/MainLayout";
import { MenuProps } from "@chambercustoms/common-components/src/components/Menu/Menu";
import { HeaderProps } from "@chambercustoms/common-components/src/components/Header/Header";
import chamberCustomsLogo from "@chambercustoms/common-components/src/assets/chamber-customs-logo.png";
import useDrawerProfile from "@chambercustoms/common-components/src/components/DrawerProfile/useDrawerProfile";

const ManagementDashboard = lazy(() => import("./pages/ManagementDashboard/ManagementDashboard"));

export type MainProps = {
  cognitoUser: {attributes: {email: string}}
}

const Main: React.FC<MainProps> = ({cognitoUser}) => {
  const userName = cognitoUser?.attributes?.email ?? 'N/A';
  const [isDrawerProfileOpen, setIsDrawerProfileOpen] = useDrawerProfile();

  const onProfileClickHandler = () =>
    setIsDrawerProfileOpen(!isDrawerProfileOpen);

  const header = {
    userName: userName,
    title: "CHAMBER CUSTOMS",
    logoUrl: chamberCustomsLogo,
    onProfileClick: onProfileClickHandler
  } as HeaderProps;

  const menuProfile = {
    items: [
      {
        label: "Logout",
        icon: <LogoutIcon />,
        onClick: async () => {
          await Auth.signOut();
          window.location.reload();
        },
      },
    ],
  } as MenuProps;

  return (
    <MainLayout
      headerProps={header}
      menuProfileProps={menuProfile}
    >
      <Switch>
        <Route exact path="/">
          <ManagementDashboard />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default Main;
