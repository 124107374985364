import { MouseEvent, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export interface MenuItemProps {
  label: string;
  to?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  icon?: ReactNode;
  items?: MenuItemProps[];
  level?: number;
}

const MenuItem = ({
  icon,
  label,
  to,
  onClick,
  items = [],
  level = 0,
}: MenuItemProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const isNested = !!items.length;

  const linkProps = onClick ? { onClick } : { component: Link, to };

  const listItemButtonProps = isNested
    ? {
        onClick: handleClick,
      }
    : { ...linkProps };

  return (
    <>
      <ListItemButton
        {...listItemButtonProps}
        sx={{ pl: level > 1 ? level * 4 : 2 }}
      >
        <ListItemIcon>
          {icon || (
            <ArrowForwardIosIcon
              sx={{ ml: "4px" }}
              fontSize="inherit"
              color="secondary"
            />
          )}
        </ListItemIcon>
        <ListItemText primary={label} />
        {isNested && !open && <ExpandMore />}
        {isNested && open && <ExpandLess />}
      </ListItemButton>
      {isNested && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((itemProps) => (
              <MenuItem
                key={itemProps.label}
                level={level + 1}
                {...itemProps}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default MenuItem;
