import { MouseEvent } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TranslateIcon from "@mui/icons-material/Translate";
import MenuIcon from "@mui/icons-material/Menu";

export interface BottomMenuProps {
  /**
   * Menu handler
   */
  onMenuClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Search handler
   */
  onSearchClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Help handler
   */
  onHelpClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Profile handler
   */
  onProfileClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Languages handler
   */
  onLanguagesClick?: (event: MouseEvent<HTMLElement>) => void;
}

const BottomMenu = ({
  onMenuClick,
  onSearchClick,
  onHelpClick,
  onProfileClick,
  onLanguagesClick,
}: BottomMenuProps): JSX.Element => (
  <Paper
    sx={{
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
    }}
    elevation={3}
  >
    <BottomNavigation showLabels>
      {onMenuClick && (
        <BottomNavigationAction
          label="Navigation"
          onClick={onMenuClick}
          icon={<MenuIcon />}
        />
      )}
      {onSearchClick && (
        <BottomNavigationAction
          label="Search"
          onClick={onSearchClick}
          icon={<SearchIcon />}
        />
      )}
      {onHelpClick && (
        <BottomNavigationAction
          label="Help"
          onClick={onHelpClick}
          icon={<HelpOutlineIcon />}
        />
      )}
      {onProfileClick && (
        <BottomNavigationAction
          label="Profile"
          onClick={onProfileClick}
          icon={<AccountCircleIcon />}
        />
      )}
      {onLanguagesClick && (
        <BottomNavigationAction
          label="Languages"
          onClick={onLanguagesClick}
          icon={<TranslateIcon />}
        />
      )}
    </BottomNavigation>
  </Paper>
);

export default BottomMenu;
