import List from "@mui/material/List";
import MenuItem, { MenuItemProps } from "../MenuItem/MenuItem";

export interface MenuProps {
  items?: MenuItemProps[];
}

const Menu = ({ items = [] }: MenuProps): JSX.Element => {
  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {items.map((itemProps) => (
        <MenuItem key={itemProps.label} {...itemProps} />
      ))}
    </List>
  );
};

export default Menu;
