import { FC } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import DrawerMenu from "../DrawerMenu/DrawerMenu";
import DrawerProfile from "../DrawerProfile/DrawerProfile";
import Header, { HeaderProps } from "../Header/Header";
import Menu, { MenuProps } from "../Menu/Menu";
import BottomMenu, { BottomMenuProps } from "../BottomMenu/BottomMenu";

export interface MainLayoutProps {
  headerProps: HeaderProps;
  bottomMenuProps?: BottomMenuProps;
  menuProps?: MenuProps;
  menuProfileProps?: MenuProps;
}

const MainLayout: FC<MainLayoutProps> = ({
  children,
  headerProps,
  bottomMenuProps,
  menuProfileProps,
  menuProps,
}) => {
  return (
    <>
      <Box sx={{ pb: 7, display: "flex", height: "100vh" }}>
        <Header {...headerProps} />
        {menuProps && (
          <DrawerMenu>
            <Menu {...menuProps} />
          </DrawerMenu>
        )}
        {menuProfileProps && (
          <DrawerProfile>
            <Menu {...menuProfileProps} />
          </DrawerProfile>
        )}
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar />
          {children}
        </Box>
      </Box>
      <Box
        sx={{
          display: { sm: "none", xs: "block" },
        }}
      >
        { bottomMenuProps && <BottomMenu {...bottomMenuProps} /> }
      </Box>
    </>
  );
};

export default MainLayout;
