import { FC } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import useDrawerProfile from "./useDrawerProfile";

export interface DrawerProfileProps {}

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
}));

const DrawerProfile: FC<DrawerProfileProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useDrawerProfile();
  return (
    <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
      {children}
    </Drawer>
  );
};

export default DrawerProfile;
