/* eslint-disable */
// NEW

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:fdf1b289-45aa-4303-afdd-b00c32b05f94",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_KUENKRR3Y",
    "aws_user_pools_web_client_id": "1gc4kkvc268322ogkl2fc9h367",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cloud_logic_custom": [
        {
            "name": "merapar-dashboard",
            "endpoint": "https://e2joprf42e.execute-api.eu-west-2.amazonaws.com/Prod",
            "region": "eu-west-2"
        },
        {
            "name": "merapar-userinfo",
            "endpoint": "https://clp9xkzwe5.execute-api.eu-west-2.amazonaws.com/Prod",
            "region": "eu-west-2"
        }
    ],
    "aws_content_delivery_bucket": "amplifyquicksightdas-20210906102527-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://d11nf2uh45er4x.cloudfront.net"
};


export default awsmobile;
