import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#307188",
    },
    secondary: {
      main: "#98AA44",
    },
    text: {
      primary: "#307188",
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0.5,
          border: 1,
          borderColor: "#F0F0F0",
          boxShadow: "10px 10px 5px 0px #C4C4C4",
        },
      },
    },
  },
  typography: {
    fontSize: 12.25
  },
});

export default theme;
