import { Suspense, useEffect, useState } from 'react';
import Amplify from 'aws-amplify';
import {
  AmplifyAuthenticator,
} from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {BrowserRouter as RouterProvider} from "react-router-dom";
import theme from "@chambercustoms/common-components/src/theme";
import {DrawerProfileContextProvider} from "@chambercustoms/common-components/src/components/DrawerProfile/useDrawerProfile";
import {DrawerMenuContextProvider} from "@chambercustoms/common-components/src/components/DrawerMenu/useDrawerMenu";
import Login from "@chambercustoms/common-components/src/components/Login/Login"
import chamberCustomsLogo from "@chambercustoms/common-components/src/assets/chamber-customs-logo.png"
import Main from "./Main";

Amplify.configure(awsconfig);

const App = () => {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<{attributes: {email: string}} | undefined>();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      // @ts-ignore
      setUser(authData);
    });
  }, []);

  if (authState === AuthState.SignedIn && user) {
    return (
      <AmplifyAuthenticator>
        <RouterProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<div>Page is Loading...</div>}>
              <DrawerProfileContextProvider>
                <DrawerMenuContextProvider>
                  <Main cognitoUser={user} />
                </DrawerMenuContextProvider>
              </DrawerProfileContextProvider>
            </Suspense>
          </ThemeProvider>
        </RouterProvider>
      </AmplifyAuthenticator>
    );
  }

  return (
      <Login logoUrl={chamberCustomsLogo} logoAlt="ChamberCustoms" />
  );
};

export default App;
