import React, { FC } from 'react';
import Box, {BoxProps} from '@mui/material/Box'
import { styled } from '@mui/material/styles';
import {
  AmplifyAuthenticator,
  AmplifySignUp, 
  AmplifySignIn, 
  AmplifyConfirmSignIn
} from '@aws-amplify/ui-react';
import "./Login.css"

export type LoginProps = {
    logoUrl?: string;
    logoAlt?: string;
}

const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: "100vh",
  minHeight: '700px',
  alignItems: 'center',
}));

const Login: FC<LoginProps> = ({ logoUrl, logoAlt }) => {
  return (
    <Wrapper>
      {logoUrl && (
        <img src={logoUrl} alt={logoAlt} style={{
          maxWidth: "200px",
          marginBottom: '50px',
        }}/>
      )}
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "password",
              inputProps: { required: true, autocomplete: "new-password" },
            },
          ]}
        />
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
        <AmplifyConfirmSignIn slot="confirm-sign-in" />
      </AmplifyAuthenticator>
    </Wrapper>
  );
};

export default Login;
