import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";

export type DrawerProfileContextType = [
  boolean,
  Dispatch<SetStateAction<boolean>>
];

export const DrawerProfileContext = createContext<DrawerProfileContextType>([
  true,
  () => {},
]);

export const DrawerProfileContextProvider: FC<{}> = ({ children }) => {
  const [isDrawerProfileOpen, setIsDrawerProfileOpen] = useState(false);

  return (
    <DrawerProfileContext.Provider
      value={[isDrawerProfileOpen, setIsDrawerProfileOpen]}
    >
      {children}
    </DrawerProfileContext.Provider>
  );
};

const useDrawerProfile = () => useContext(DrawerProfileContext);

export default useDrawerProfile;
